import React, { lazy } from 'react';

const Customer = lazy(() => import('../pages/Customer/Customer'));
const Terms = lazy(() => import('../pages/Customer/Terms/Terms'));
const Policy = lazy(() => import('../pages/Customer/Policy/Policy'));
const Accessibility = lazy(
  () => import('../pages/Customer/Accessibility/Accessibility')
);
// const GoodFaith = lazy(() => import('../pages/Customer/GoodFaith/GoodFaith'));
const Referral = lazy(() => import('../pages/Customer/Referral/Referral'));
const ArticleFDA = lazy(() => import('../pages/Customer/Article/ArticleFDA'));
const ArticleMedicalGrade = lazy(
  () => import('../pages/Customer/Article/ArticleMedicalGrade')
);
const MobileHome = lazy(
  () => import('../pages/Customer/MobileHome/MobileHome')
);

interface Route {
  path: string;
  element: React.LazyExoticComponent<() => JSX.Element>;
}
const StaticRoutes: Route[] = [
  { path: '/', element: Customer },
  { path: '/terms-of-service', element: Terms },
  { path: '/privacy-policy', element: Policy },
  { path: '/accessibility', element: Accessibility },
  { path: '/referral', element: Referral },
  { path: '/what-does-it-mean-to-be-fda-listed', element: ArticleFDA },
  {
    path: '/what-is-medical-grade-certification',
    element: ArticleMedicalGrade,
  },
  { path: '/mobile-home', element: MobileHome },
];

export default StaticRoutes;
