import React, { useState } from 'react';
import {
  Box,
  Typography,
  Container,
  Button,
  Alert,
  TextField,
  CircularProgress,
} from '@mui/material';
import '../../../i18n/config';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Amplify, Auth } from 'aws-amplify';
import awsExports from '../../../aws-exports';

Amplify.configure(awsExports);

export default function ConfirmSignUp() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [code, setCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const params = useParams();
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const email = params?.useremail ? params?.useremail : '';
    setLoading(true);
    Auth.confirmSignUp(email, code)
      .then((_success) => {
        // console.log(success);
        setLoading(false);
        navigate('/login');
      })
      .catch((err) => {
        // console.log(err);
        setLoading(false);
        setError(err.message);
      });
  };
  const handleResend = (event: React.SyntheticEvent) => {
    event.preventDefault();
    const email = params?.useremail ? params?.useremail : '';
    setLoading(true);
    Auth.resendSignUp(email)
      .then((_response) => {
        setLoading(false);
        // console.log(response);
      })
      .catch((err) => {
        // console.log(err);
        setLoading(false);
        setError(err.message);
      });
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        flexGrow: '1',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          p: 3,
        }}
      >
        <Typography component="h1" variant="h5">
          {t('adminpage.confirm.title')}
        </Typography>
        <Box
          component="form"
          noValidate
          onSubmit={handleSubmit}
          sx={{
            mt: 3,
            width: '100%',
            position: 'relative',
          }}
        >
          {error && (
            <Alert severity="error" sx={{ mb: 1 }}>
              {error}
            </Alert>
          )}
          <TextField
            required
            fullWidth
            name="code"
            label={t('adminpage.confirm.form.code')}
            value={code}
            onChange={(e) => setCode(e.target.value)}
          />
          <Typography
            sx={{
              cursor: 'pointer',
              pt: 1,
              '&:hover': {
                textDecoration: 'underline',
              },
            }}
            onClick={(e) => handleResend(e)}
          >
            {t('adminpage.confirm.form.resend')}
          </Typography>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={loading}
          >
            {t('adminpage.confirm.form.submit')}
          </Button>
          {loading && (
            <Box
              sx={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                top: 0,
              }}
            >
              <CircularProgress size={48} />
            </Box>
          )}
        </Box>
      </Box>
    </Container>
  );
}
