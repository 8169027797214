import * as React from 'react';
import { Box, Container } from '@mui/material';
import ApplePayIcon from '../../../assets/images/applepay.svg';
import GPayIcon from '../../../assets/images/gpay.svg';
import VisaIcon from '../../../assets/images/visa.svg';
import DiscoverIcon from '../../../assets/images/discover.svg';
import CryIcon from '../../../assets/images/cry.svg';
import MasterCardIcon from '../../../assets/images/mastercard.png';
import AmexIcon from '../../../assets/images/amex.png';
import JCBIcon from '../../../assets/images/JCB.png';

function ShopFooter() {
  return (
    <Box sx={{ background: '#F4F4F4' }}>
      <Container sx={{ py: 3, '& img': { width: 50, height: 'fit-content' } }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'end',
            gap: 4,
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
        >
          <Box src={ApplePayIcon} component="img" />
          <Box src={GPayIcon} component="img" />
          <Box src={VisaIcon} component="img" />
          <Box src={MasterCardIcon} component="img" />
          <Box src={AmexIcon} component="img" />
          <Box src={DiscoverIcon} component="img" />
          <Box src={JCBIcon} component="img" />
          <Box src={CryIcon} component="img" />
        </Box>
      </Container>
    </Box>
  );
}

export default ShopFooter;
