import React, { useCallback, useState } from 'react';
import {
  Avatar,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
  Grid,
  Box,
  Typography,
  Container,
  CssBaseline,
  Alert,
  CircularProgress,
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import '../../../i18n/config';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../hooks';
import { loginUserAction } from '../../../slices/authReducer';
import loginUserService from '../../../services/authenticationService';

interface User {
  attributes: { [key: string]: string };
}

interface UserAuthDataInterface {
  user: User;
  token: string;
}

export default function SignIn() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const data = {
        email,
        password,
      };
      setLoading(true);
      await loginUserService(data)
        .then((response: UserAuthDataInterface) => {
          setLoading(false);
          const user = {
            firstName: response.user.attributes['custom:first_name'],
            lastName: response.user.attributes['custom:last_name'],
            email: response.user.attributes.email,
            sub: response.user.attributes.sub,
          };
          dispatch(loginUserAction({ token: response.token, user }));
          navigate('/admin/dashboard');
          // window.location.href = '/admin/dashboard';
        })
        .catch((err) => {
          setLoading(false);
          setError((err as Error).message);
        });
    },
    [email, password, dispatch, setLoading, navigate]
  );

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        flexGrow: '1',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          position: 'relative',
          m: 1,
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {t('adminpage.signIn.title')}
        </Typography>
        {error && (
          <Alert severity="error" sx={{ width: '100%', mt: 2 }}>
            {error}
          </Alert>
        )}
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label={t('adminpage.signIn.form.email')}
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label={t('adminpage.signIn.form.password')}
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label={t('adminpage.signIn.form.remember')}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={loading}
          >
            {t('adminpage.signIn.form.submit')}
          </Button>
          {loading && (
            <Box
              sx={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                top: 0,
              }}
            >
              <CircularProgress size={48} />
            </Box>
          )}
          <Grid container>
            <Grid item xs>
              <Link href="/forgot-password" variant="body2">
                {t('adminpage.signIn.form.forgot')}
              </Link>
            </Grid>
            <Grid item>
              <Link href="/signup" variant="body2">
                {t('adminpage.signIn.form.signup')}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}
