import { Amplify, Auth } from 'aws-amplify';
import awsExports from '../aws-exports';

Amplify.configure(awsExports);

interface DataOption {
  email: string;
  password: string;
}

const loginUserService = (data: DataOption) => {
  return Auth.signIn(data.email, data.password)
    .then((response) => {
      return {
        user: response,
        token: response?.signInUserSession.idToken.jwtToken,
      };
    })
    .catch((error) => {
      throw error;
    });
};

export default loginUserService;
