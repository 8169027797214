import * as React from 'react';
import { LinearProgress, Stack } from '@mui/material';

function LinearLoader() {
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          clearInterval(timer);
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 300);
  }, []);

  return (
    <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
      <LinearProgress
        color="secondary"
        variant="determinate"
        value={progress}
      />
    </Stack>
  );
}

export default LinearLoader;
