import React, { lazy } from 'react';

const Dashboard = lazy(() => import('../pages/Admin/Dashboard/Dashboard'));
const Devices = lazy(() => import('../pages/Admin/Device/Devices'));
const Sessions = lazy(() => import('../pages/Admin/Session/Sessions'));
const Channels = lazy(() => import('../pages/Admin/Channel/Channels'));
const Patients = lazy(() => import('../pages/Admin/Patient/Patients'));
const EnlightenTv = lazy(
  () => import('../pages/Admin/EnlightenTv/EnlightenTv')
);
const FrontDesk = lazy(() => import('../pages/Admin/FrontDesk/FrontDesk'));

interface Route {
  path: string;
  element: React.LazyExoticComponent<() => JSX.Element>;
}
const AdminRoutes: Route[] = [
  { path: 'dashboard', element: Dashboard },
  { path: 'devices', element: Devices },
  { path: 'sessions', element: Sessions },
  { path: 'channels', element: Channels },
  { path: 'patients', element: Patients },
  { path: 'enlightentv', element: EnlightenTv },
  { path: 'frontdesk', element: FrontDesk },
];

export default AdminRoutes;
