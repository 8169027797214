import React, { lazy } from 'react';

const Shop = lazy(() => import('../pages/Customer/Shop/Shop'));
const ShopInner = lazy(() => import('../pages/Customer/ShopInner/ShopInner'));

interface Route {
  path: string;
  element: React.LazyExoticComponent<() => JSX.Element>;
}
const CustomerRoutes: Route[] = [
  { path: '', element: Shop },
  { path: 'inner/:id', element: ShopInner },
];

export default CustomerRoutes;
