import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoginState, LoginSuccessPayload } from '../actions/types';
import type { RootState } from '../store/store';

const initialState: LoginState = {
  token: null,
  user: null,
  error: null,
  mautic: {
    status: null,
    msg: null,
  },
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginUserAction: (state, action: PayloadAction<LoginSuccessPayload>) => {
      state.token = action.payload.token;
      state.user = action.payload.user;
    },
    logoutUserAction: (state) => {
      state.user = null;
      state.token = null;
    },
    mauticSubmitAction: (state, action: PayloadAction<unknown>) => {
      state.mautic = action.payload;
    },
  },
});

export const { loginUserAction, logoutUserAction, mauticSubmitAction } =
  authSlice.actions;
export const selectToken = (state: RootState) => state.auth.token;
export const selectUser = (state: RootState) => state.auth.user;
export const selectMsg = (state: RootState) => state.auth.mautic;

export default authSlice.reducer;
