import * as React from 'react';
import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import ShopFooter from '../components/customer/shop/ShopFooter';
import ShopHeader from '../components/customer/shop/ShopHeader';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  /* eslint-disable react/jsx-props-no-spreading */
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function CustomerLayout() {
  const [open, setOpen] = React.useState(true);

  // const handleClick = () => {
  //   setOpen(true);
  // };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <Box
      sx={{
        fontFamily: 'Montserrat-Regular',
        color: '#707070',
      }}
    >
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          This is a success message!
        </Alert>
      </Snackbar>
      <ShopHeader />
      <Outlet />
      <ShopFooter />
    </Box>
  );
}

export default CustomerLayout;
