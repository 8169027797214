import * as React from 'react';
import {
  AppBar,
  Box,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import '../../i18n/config';
import { useTranslation } from 'react-i18next';
import logo from '../../assets/images/logo.png';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { logoutUserAction } from '../../slices/authReducer';
import { RootState } from '../../store/store';

interface MenuOption {
  menu: string;
  path: string;
}

const settings: MenuOption[] = [
  { menu: 'adminpage.header.settings.menu1', path: '/profile' },
  { menu: 'adminpage.header.settings.menu2', path: '/account' },
  { menu: 'adminpage.header.settings.menu3', path: '/admin/dashboard' },
];

const options: MenuOption[] = [
  { menu: 'adminpage.header.menu.menu1', path: '/admin/dashboard' },
  { menu: 'adminpage.header.menu.menu2', path: '/admin/devices' },
  { menu: 'adminpage.header.menu.menu3', path: '/admin/sessions' },
  { menu: 'adminpage.header.menu.menu4', path: '/admin/channels' },
  { menu: 'adminpage.header.menu.menu5', path: '/admin/patients' },
  { menu: 'adminpage.header.menu.menu6', path: '/admin/enlightentv' },
];

function Header() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const user = useAppSelector((state: RootState) => state.auth.user);
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const open = Boolean(anchorElNav);
  const openUserMenu = Boolean(anchorElUser);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = (path: string) => {
    setAnchorElUser(null);
    setTimeout(() => {
      if (path) navigate(path);
    });
  };

  const handleLogout = () => {
    handleCloseUserMenu('');
    dispatch(logoutUserAction());
  };

  return (
    <AppBar position="static">
      <Toolbar color="primary">
        <IconButton
          id="long-button"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
          aria-controls={open ? 'long-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleOpenNavMenu}
        >
          {!open && <MenuIcon />}
          {open && <CloseIcon />}
        </IconButton>
        <Menu
          id="long-menu"
          MenuListProps={{
            'aria-labelledby': 'long-button',
          }}
          anchorEl={anchorElNav}
          open={open}
          onClose={handleCloseNavMenu}
          sx={{
            mt: '17px',
            '& .MuiMenu-paper': {
              width: { xs: '100%', sm: '20ch' },
            },
          }}
        >
          {options.map((option, key) => (
            <MenuItem
              key={option.menu}
              selected={option.menu === 'Pyxis'}
              sx={{
                borderBottom: key !== options.length - 1 ? '1px solid' : '',
                borderColor: 'gray.dark',
                p: 0,
              }}
              onClick={handleCloseNavMenu}
            >
              <Link
                href={option.path}
                underline="none"
                sx={{ width: '100%', py: 1, px: 3 }}
              >
                {t(option.menu)}
              </Link>
            </MenuItem>
          ))}
        </Menu>
        <Box component="img" src={logo} alt="Logo" sx={{ py: 1, height: 50 }} />
        <Box sx={{ flexGrow: 1 }} />
        <Typography
          variant="h6"
          color="inherit"
          component="div"
          sx={{ display: { xs: 'none', sm: 'block' } }}
        >
          {`${user?.firstName} ${user?.lastName}`}
        </Typography>
        <IconButton
          id="settings-button"
          edge="start"
          color="inherit"
          aria-label="menu"
          aria-controls={openUserMenu ? 'menu-appbar' : undefined}
          aria-expanded={openUserMenu ? 'true' : undefined}
          onClick={handleOpenUserMenu}
          sx={{ mr: 2, ml: 2 }}
        >
          <AccountCircleIcon fontSize="large" />
        </IconButton>
        <Menu
          id="menu-appbar"
          MenuListProps={{
            'aria-labelledby': 'settings-button',
          }}
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          sx={{ mt: '12px' }}
          open={openUserMenu}
          onClose={handleCloseUserMenu}
        >
          {settings.map((setting) => (
            <MenuItem
              key={setting.menu}
              onClick={() => handleCloseUserMenu(setting.path)}
            >
              <Typography textAlign="center">{t(setting.menu)}</Typography>
            </MenuItem>
          ))}
          <MenuItem onClick={handleLogout}>
            <Typography textAlign="center">
              {t('adminpage.header.logout')}
            </Typography>
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
