/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Box } from '@mui/material';
import Footer from '../components/common/Footer';
import AdminLayout from '../layout/AdminLayout';
import AdminRoutes from './AdminRoutes';
import SignIn from '../pages/Admin/SignIn';
import SignUp from '../pages/Admin/SignUp';
import ForgotPassword from '../pages/Admin/ForgotPassword';
import StaticLayout from '../layout/StaticLayout';
import StaticRoutes from './StaticRoutes';
import NoMenuRoutes from './NoMenuRoutes';
import CustomerLayout from '../layout/CustomerLayout';
import CustomerRoutes from './CustomerRoutes';
import ConfirmSignUp from '../pages/Admin/SignUp/ConfirmSignUp';
import ProtectedRoute, { ProtectedRouteProps } from './ProtectedRoute';
import { useAppSelector } from '../hooks';
import NoMenuLayout from '../layout/NoMenuLayout';

function Routers() {
  const { token } = useAppSelector((state) => state.auth);
  // console.log(!!token);
  const defaultProtectedRouteProps: Omit<ProtectedRouteProps, 'outlet'> = {
    isAuthenticated: !!token,
    authenticationPath: '/login',
  };

  return (
    <Box minHeight="100vh" display="flex" flexDirection="column">
      <Routes>
        <Route
          path="/admin"
          element={
            <ProtectedRoute
              {...defaultProtectedRouteProps}
              outlet={<AdminLayout />}
            />
          }
        >
          {AdminRoutes.map((route) => (
            <Route
              path={route.path}
              element={<route.element />}
              key={`Route ${route.path}`}
            />
          ))}
        </Route>
        <Route path="/" element={<StaticLayout />}>
          {StaticRoutes.map((route) => (
            <Route
              path={route.path}
              element={<route.element />}
              key={`Route ${route.path}`}
            />
          ))}
        </Route>
        <Route path="/" element={<NoMenuLayout />}>
          {NoMenuRoutes.map((route) => (
            <Route
              path={route.path}
              element={<route.element />}
              key={`Route ${route.path}`}
            />
          ))}
        </Route>
        <Route path="/shop" element={<CustomerLayout />}>
          {CustomerRoutes.map((route) => (
            <Route
              path={route.path}
              element={<route.element />}
              key={`Route ${route.path}`}
            />
          ))}
        </Route>
        <Route
          path="/ProSetUp"
          Component={() => {
            window.location.href = 'https://youtu.be/Ypqd_6wtVCQ';
            return null;
          }}
        />
        <Route path="/login" element={<SignIn />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/confirm/:useremail" element={<ConfirmSignUp />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
      </Routes>
      <Footer />
    </Box>
  );
}

export default Routers;
