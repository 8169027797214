import { Box } from '@mui/material';
import * as React from 'react';
import { useLocation, Outlet } from 'react-router-dom';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import CustomerFooter from '../components/customer/StaticFooter';
import CustomerHeader from '../components/customer/StaticHeader';
import { useAppDispatch, useAppSelector } from '../hooks';
import { RootState } from '../store/store';
import { mauticSubmitAction } from '../slices/authReducer';

export interface State extends SnackbarOrigin {
  open: boolean;
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  /* eslint-disable react/jsx-props-no-spreading */
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function StaticLayout() {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const mautic = useAppSelector((state: RootState) => state.auth.mautic);
  const defaultProps: SnackbarOrigin = React.useMemo(
    () => ({
      vertical: 'top',
      horizontal: 'right',
    }),
    []
  );
  const [state, setState] = React.useState<State>({
    open: false,
    vertical: 'top',
    horizontal: 'right',
  });

  const { vertical, horizontal, open } = state;

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setState({ open: false, ...defaultProps });
    dispatch(mauticSubmitAction({}));
  };

  React.useEffect(() => {
    if (mautic.msg) {
      setState({ open: true, ...defaultProps });
    }
  }, [defaultProps, mautic]);

  return (
    <Box
      sx={{
        fontFamily: 'Montserrat-Regular',
        color: '#707070',
      }}
    >
      {mautic.msg !== null && (
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={mautic.status}
            sx={{ width: '100%', fontFamily: 'Montserrat-Regular' }}
          >
            {mautic.msg}
          </Alert>
        </Snackbar>
      )}
      <CustomerHeader isHome={location.pathname === '/'} />
      <Outlet />
      <CustomerFooter />
    </Box>
  );
}

export default StaticLayout;
