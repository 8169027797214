import React, { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import { CacheProvider } from '@emotion/react';
import { ThemeProvider } from '@mui/material';
import createEmotionCache from './utils/create-emotion-catch';
import createTheme from './theme';
import Routers from './routes/Routes';

const clientSideEmotionCache = createEmotionCache();

function App() {
  return (
    <CacheProvider value={clientSideEmotionCache}>
      <ThemeProvider
        theme={createTheme({
          direction: 'ltr',
          responsiveFontSizes: true,
          mode: 'light',
        })}
      >
        <BrowserRouter>
          <Suspense>
            <Routers />
          </Suspense>
        </BrowserRouter>
      </ThemeProvider>
    </CacheProvider>
  );
}

export default App;
