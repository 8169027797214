import * as React from 'react';
import {
  Avatar,
  Button,
  TextField,
  Link,
  Grid,
  Box,
  Typography,
  Container,
  CssBaseline,
  Alert,
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import '../../../i18n/config';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Amplify, Auth } from 'aws-amplify';
import awsExports from '../../../aws-exports'; // eslint-disable-line

Amplify.configure(awsExports);

export default function ForgotPassword() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [error, setError] = React.useState('');
  const [confirmError, setConfirmError] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [confirm, setConfirm] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [sentEmail, setSentEmail] = React.useState(false);
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    Auth.forgotPassword(email)
      .then((_success) => {
        // console.log(success);
        setSentEmail(true);
      })
      .catch((err) => {
        setError(err.message);
      });
  };
  const handleSubmitPassword = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    Auth.forgotPasswordSubmit(email, confirm, password)
      .then((_success) => {
        // console.log(success);
        navigate('/login');
      })
      .catch((err) => {
        setConfirmError(err.message);
      });
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        flexGrow: '1',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {t('adminpage.forgotPassword.title')}
        </Typography>
        {!sentEmail && (
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1, width: '100%' }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label={t('adminpage.forgotPassword.form.email')}
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {error && <Alert severity="error">{error}</Alert>}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              {t('adminpage.forgotPassword.form.submit')}
            </Button>
          </Box>
        )}
        {sentEmail && (
          <Box
            component="form"
            onSubmit={handleSubmitPassword}
            noValidate
            sx={{ mt: 1, width: '100%' }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="confirm"
              label={t('adminpage.forgotPassword.form.code')}
              name="confirm"
              value={confirm}
              onChange={(e) => setConfirm(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              type="password"
              id="password"
              label={t('adminpage.forgotPassword.form.password')}
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {confirmError && <Alert severity="error">{confirmError}</Alert>}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              {t('adminpage.forgotPassword.form.submit')}
            </Button>
          </Box>
        )}
        <Grid container>
          <Grid item xs>
            <Link href="/login" variant="body2">
              {t('adminpage.forgotPassword.form.signin')}
            </Link>
          </Grid>
          <Grid item>
            <Link href="/signup" variant="body2">
              {t('adminpage.forgotPassword.form.signup')}
            </Link>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}
