import React, { useState } from 'react';
import {
  AppBar,
  Box,
  Toolbar,
  Divider,
  // IconButton,
  Typography,
} from '@mui/material';
import { HashLink } from 'react-router-hash-link';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import '../../i18n/config';
import { useTranslation } from 'react-i18next';
import logo from '../../assets/images/logo.svg';
import PrimaryButton from './PrimaryButton';

interface propsOption {
  isHome: boolean;
}

interface MenuOption {
  menu: string;
  path: string;
}

const menuList: MenuOption[] = [
  { menu: 'homepage.header.menu.menu1', path: '/#top' },
  { menu: 'homepage.header.menu.menu2', path: '/#faqs' },
  { menu: 'homepage.header.menu.menu3', path: '/#be-enlightened' },
  { menu: 'homepage.header.menu.menu5', path: '/#contact' },
];

function StaticHeader({ isHome }: propsOption) {
  const { t } = useTranslation();
  const [openDropDown, setOpenDropDown] = useState(false);
  const handleClickDropdownMenu = () => {
    setOpenDropDown((prev) => !prev);
  };

  return (
    <AppBar
      position="fixed"
      color="primary"
      sx={{
        background: isHome ? 'rgba(47, 29, 38, .38)' : 'rgba(47, 29, 38)',
        boxShadow: 'none',
        borderBottom: isHome ? 'none' : '3px solid #BF357A',
      }}
    >
      <Toolbar>
        <Box
          component="img"
          src={logo}
          alt="Logo"
          sx={{
            py: 1,
            // height: { xs: 'auto', md: 91 },
            // width: { xs: '250px', md: 'auto' },
            width: '250px',
            height: 'auto',
          }}
        />
        <Box sx={{ flexGrow: 1 }} />
        <Box
          sx={{
            display: { xs: 'none', md: 'flex' },
            justifyContent: 'space-between',
            gap: '1rem',
            paddingRight: '1rem',
            textTransform: 'uppercase',
            alignItems: 'center',
          }}
        >
          {menuList.map((item) => (
            <Box key={item.menu}>
              <HashLink to={item.path} style={{ textDecoration: 'none' }}>
                <Typography
                  color="#FFFFFF"
                  sx={{
                    '&:hover': { color: '#BF357A' },
                  }}
                >
                  {t(item.menu)}
                </Typography>
              </HashLink>
            </Box>
          ))}
          <Box key="buttons">
            <PrimaryButton
              label={t('homepage.header.shopButton')}
              component="link"
              onPress={() =>
                window.open('https://shop.dahliahealth.com', '_self')
              }
              sx={{ mr: 2 }}
            />
            <PrimaryButton
              label={t('homepage.header.loginButton')}
              component="link"
              onPress={() =>
                window.open(
                  'https://portal.dahliahealth.com/auth/signin',
                  '_self'
                )
              }
              sx={{ mr: 2 }}
            />
            {/* <IconButton
              id="settings-button"
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={() => navigate('/login')}
            >
              <AccountCircleIcon sx={{ fontSize: '40px' }} />
            </IconButton> */}
          </Box>
        </Box>
        <Box
          onClick={handleClickDropdownMenu}
          sx={{
            display: { xs: 'block', md: 'none' },
          }}
        >
          {!openDropDown && <MenuIcon />}
          {openDropDown && <CloseIcon />}
        </Box>
      </Toolbar>
      {openDropDown && (
        <Box
          sx={{
            background: '#2F1D2644',
            fontWeight: 'bold',
            display: { xs: 'flex', md: 'none' },
            flexDirection: 'column',
            p: 3,
            textTransform: 'uppercase',
          }}
        >
          {menuList.map((item) => (
            <Box key={item.menu}>
              <Box sx={{ py: 1 }} onClick={() => setOpenDropDown(false)}>
                <HashLink to={item.path} style={{ textDecoration: 'none' }}>
                  <Typography
                    color="#FFFFFF"
                    sx={{
                      '&:hover': { color: '#BF357A' },
                    }}
                  >
                    {t(item.menu)}
                  </Typography>
                </HashLink>
              </Box>
              {item.menu !== 'Contact' && (
                <Divider sx={{ borderColor: '#FFFFFF' }} />
              )}
            </Box>
          ))}
          <Box key="buttons" sx={{ pt: 2 }}>
            <PrimaryButton
              label={t('homepage.header.shopButton')}
              component="link"
              onPress={() =>
                window.open('https://shop.dahliahealth.com', '_self')
              }
              sx={{ mr: 2 }}
            />
            <PrimaryButton
              label={t('homepage.header.loginButton')}
              component="link"
              onPress={() =>
                window.open(
                  'https://portal.dahliahealth.com/auth/signin',
                  '_self'
                )
              }
              sx={{ mr: 2 }}
            />
            {/* <IconButton
              id="settings-button"
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={() => navigate('/login')}
            >
              <AccountCircleIcon sx={{ fontSize: '40px' }} />
            </IconButton> */}
          </Box>
        </Box>
      )}
    </AppBar>
  );
}

export default StaticHeader;
