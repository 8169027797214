import * as React from 'react';
import { Box, Container, Divider, Link, Typography } from '@mui/material';
import '../../i18n/config';
import { useTranslation } from 'react-i18next';
import logo from '../../assets/images/logo.svg';

interface MenuOption {
  menu: string;
  path: string;
}

const menuList: MenuOption[] = [
  { menu: 'homepage.footer.menu.menu1', path: '/accessibility' },
  { menu: 'homepage.footer.menu.menu2', path: '/privacy-policy' },
  { menu: 'homepage.footer.menu.menu3', path: '/terms-of-service' },
];

function StaticFooter() {
  const { t } = useTranslation();

  return (
    <Box sx={{ background: '#2F1D26', borderTop: '3px solid #BF357A' }}>
      <Container sx={{ py: 5 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            gap: { xs: '1rem', md: '3rem' },
            alignItems: 'end',
          }}
        >
          <Box
            component="img"
            src={logo}
            alt="Logo"
            sx={{ py: 1, height: 64 }}
          />
          <Divider
            sx={{
              borderColor: '#FFFFFF32',
              display: { md: 'none' },
              width: '100%',
            }}
          />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-evenly',
              flexWrap: 'wrap',
              paddingRight: '1rem',
              gap: '2rem',
              fontWeight: 'bold',
            }}
          >
            {menuList.map((item) => (
              <Box key={item.menu}>
                <Link href={item.path} underline="none" color="#FFFFFF">
                  {t(item.menu)}
                </Link>
              </Box>
            ))}
          </Box>
        </Box>

        <Divider sx={{ my: 3, borderColor: '#FFFFFF32' }} />
        <Typography color="#FFFFFF">
          {t('homepage.footer.description')}
        </Typography>
      </Container>
    </Box>
  );
}

export default StaticFooter;
