import React, { lazy } from 'react';

const Trailer = lazy(() => import('../pages/Customer/Trailer/Trailer'));
const PTXInfo = lazy(() => import('../pages/Customer/PTXInfo/PTXInfo'));

interface Route {
  path: string;
  element: React.LazyExoticComponent<() => JSX.Element>;
}
const NoMenuRoutes: Route[] = [
  { path: '/trailer', element: Trailer },
  { path: '/ptxinfo', element: PTXInfo },
];

export default NoMenuRoutes;
