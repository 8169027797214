import * as React from 'react';
import { Box, Typography } from '@mui/material';

export default function Footer() {
  React.useEffect(() => {
    const isChrome =
      /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
    const isSafari = /AppleWebKit/.test(navigator.userAgent);
    if (window.location.hash && (isChrome || isSafari)) {
      setTimeout(() => {
        const { hash } = window.location;
        window.location.hash = '';
        window.location.hash = hash;
      }, 300);
    }
  }, []);

  const year = new Date().getFullYear();

  return (
    <Box px="24px" py="12px" sx={{ backgroundColor: 'custom.dark' }}>
      <Typography color="custom.white" fontSize="14px" textAlign="center">
        Copyright &copy; 2020-{year}, Dahlia Health, LLC. All Rights Reserved.
        No reproduction is allowed without written permission.
      </Typography>
    </Box>
  );
}
