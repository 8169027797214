import * as React from 'react';
import { SxProps, Theme, styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

const ColorButton = styled(Button)<ButtonProps>(() => ({
  fontWeight: 'bold',
  fontSize: '1rem',
  borderRadius: '2rem',
  textTransform: 'unset',
  paddingRight: '20px',
  paddingLeft: '20px',
  minWidth: '100px',
}));

interface PrimaryButtonInput {
  label: string;
  component?: string;
  onPress?: React.MouseEventHandler<HTMLButtonElement>;
  loading?: boolean;
  sx?: SxProps<Theme>;
}

export default function PrimaryButton(params: PrimaryButtonInput) {
  // const gotoUrl = () => {
  //   window.location.href = to;
  // };
  const { label, component, onPress, loading, sx } = params;
  return (
    <>
      {component === 'link' && (
        <ColorButton
          variant="contained"
          color="secondary"
          onClick={onPress}
          sx={sx}
        >
          {label}
        </ColorButton>
      )}
      {component !== 'link' && (
        <ColorButton
          variant="contained"
          color="secondary"
          sx={sx}
          onClick={onPress}
        >
          {loading ? <CircularProgress color="inherit" size={24} /> : label}
        </ColorButton>
      )}
    </>
  );
}
