import { ThemeOptions } from '@mui/material';

declare module '@mui/material/styles' {
  interface PaletteOptions {
    gray: {
      light: string;
      main: string;
      dark: string;
    };
    custom: {
      main?: string;
      dark?: string;
    };
  }
}

// Colors
const primary = {
  main: '#2F1D26',
  dark: '#2F1D26',
};
const secondary = {
  main: '#BF357A',
  light: '#BF357A',
  dark: '#EB4C9B',
};

const success = {
  main: '#00C17E',
};

const error = {
  main: '#DE1414',
};

const warning = {
  main: '#FFE969',
};

const text = {
  primary: '#707070',
  secondary: '#585858',
};

const gray = {
  light: '#f4f4f4',
  main: '#fcf2f7',
  dark: '#e8e8e8',
};

const custom = {
  dark: '#000000',
  white: '#FFFFFF',
};

const lightThemeOptions: ThemeOptions = {
  palette: {
    primary,
    secondary,
    success,
    error,
    warning,
    text,
    gray,
    custom,
  },
};

export default lightThemeOptions;
