import React, { useState } from 'react';
import {
  AppBar,
  Box,
  Divider,
  IconButton,
  Toolbar,
  Typography,
} from '@mui/material';
import { HashLink } from 'react-router-hash-link';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
// import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';
import '../../../i18n/config';
import { useTranslation } from 'react-i18next';
import logo from '../../../assets/images/logo.png';

interface MenuOption {
  menu: string;
  path: string;
}

const menuList: MenuOption[] = [
  { menu: 'shoppage.header.menu.menu1', path: '/#top' },
  { menu: 'shoppage.header.menu.menu2', path: '/#faqs' },
  { menu: 'shoppage.header.menu.menu3', path: '/#be-enlightened' },
  { menu: 'homepage.header.menu.menu4', path: '/referral' },
  { menu: 'shoppage.header.menu.menu5', path: '/#contact' },
];

function ShopHeader() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openDropDown, setOpenDropDown] = useState(false);
  const handleClickDropdownMenu = () => {
    setOpenDropDown((prev) => !prev);
  };

  return (
    <AppBar position="static">
      <Toolbar color="primary" sx={{ borderBottom: '3px solid #BF357A' }}>
        <Box
          onClick={handleClickDropdownMenu}
          sx={{
            display: { xs: 'block', md: 'none' },
          }}
        >
          {!openDropDown && <MenuIcon fontSize="large" />}
          {openDropDown && <CloseIcon fontSize="large" />}
        </Box>
        <Box component="img" src={logo} alt="Logo" sx={{ py: 1, height: 50 }} />
        <Box sx={{ flexGrow: 1 }} />
        <Box
          sx={{
            display: { xs: 'none', md: 'flex' },
            justifyContent: 'space-between',
            gap: '1rem',
            paddingRight: '1rem',
            textTransform: 'uppercase',
            alignItems: 'center',
          }}
        >
          {menuList.map((item) => (
            <Box key={item.menu}>
              <HashLink to={item.path} style={{ textDecoration: 'none' }}>
                <Typography
                  color="#FFFFFF"
                  sx={{
                    '&:hover': { color: '#BF357A' },
                  }}
                >
                  {t(item.menu)}
                </Typography>
              </HashLink>
            </Box>
          ))}
        </Box>
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
          {/* <IconButton
            sx={{
              color: '#FFFFFF',
              background: '#707070',
              '&:hover': {
                color: '#707070',
                background: '#FFFFFF',
              },
            }}
          >
            <SearchIcon fontSize="small" />
          </IconButton> */}
          <IconButton
            sx={{
              color: '#FFFFFF',
              background: '#BF357A',
              height: 'fit-content',
              '&:hover': {
                color: '#BF357A',
                background: '#FFFFFF',
              },
            }}
          >
            <ShoppingCartIcon fontSize="small" />
          </IconButton>
          <IconButton
            id="settings-button"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => navigate('/login')}
          >
            <AccountCircleIcon sx={{ fontSize: '36px' }} />
          </IconButton>
          {/* <IconButton
            sx={{
              color: '#2F1D26',
              background: '#FFFFFF',
              p: 0,
              '&:hover': {
                color: '#FFFFFF',
                background: '#2F1D26',
              },
            }}
          >
            <AccountCircleIcon sx={{ fontSize: '36px' }} />
          </IconButton> */}
        </Box>
      </Toolbar>
      {openDropDown && (
        <Box
          sx={{
            background: '#2F1D2644',
            fontWeight: 'bold',
            display: { xs: 'flex', md: 'none' },
            flexDirection: 'column',
            p: 3,
            textTransform: 'uppercase',
          }}
        >
          {menuList.map((item) => (
            <Box key={item.menu}>
              <Box sx={{ py: 1 }} onClick={() => setOpenDropDown(false)}>
                <HashLink to={item.path} style={{ textDecoration: 'none' }}>
                  <Typography
                    color="#FFFFFF"
                    sx={{
                      '&:hover': { color: '#BF357A' },
                    }}
                  >
                    {t(item.menu)}
                  </Typography>
                </HashLink>
              </Box>
              {item.menu !== 'Contact' && (
                <Divider sx={{ borderColor: '#FFFFFF' }} />
              )}
            </Box>
          ))}
        </Box>
      )}
    </AppBar>
  );
}

export default ShopHeader;
