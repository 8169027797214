import { ThemeOptions } from '@mui/material';

const baseThemeOptions: ThemeOptions = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1000,
      lg: 1200,
      xl: 1920,
    },
  },
  components: {
    MuiCssBaseline: {},
  },
  direction: 'ltr',
  shape: {
    borderRadius: 8,
  },
  typography: {
    fontFamily: '',
  },
};

export default baseThemeOptions;
