import React from 'react';
import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';
import Header from '../components/common/Header';
import LinearLoader from '../components/common/LinearProgress';

function AdminLayout() {
  return (
    <Box flexGrow="1">
      <Header />
      <LinearLoader />
      <Outlet />
    </Box>
  );
}

export default AdminLayout;
